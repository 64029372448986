body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-liveStream1 {
  height: 270px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets/images/live-stream1.png");
}

.bg-liveStream2 {
  height: 270px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets/images/live-stream2.png");
}

.bg-podcast1 {
  height: 270px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets/images/podcast1.png");
}

.bg-podcast2 {
  height: 270px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets/images/podcast2.png");
}

.pad-10 {
  padding-bottom: 50px !important;
}

.m-top-minus-50 {
  margin-top: -60px;
}

.m-l-10 {
  margin-left: 10%;
}

.m-r-10 {
  margin-right: 10%;
}

.m-l-r-5 {
  margin-left: 5%;
  margin-right: 5%;
}

.corousel-btn-indicators {
  margin-left: 10%;
  border-radius: 50%;
  height: 40px;
  justify-content: center;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  background: #b0dac7;
  width: 40px;
}

.bg-gradientSea {
  background: linear-gradient(265.38deg, #E3D6FF 24.15%, #B0DAC7 79.97%);
}

.border-box {
  border: 2px solid #000;
  border-radius: 10px;
  padding-top: 45px;
  padding-bottom: 45px;
}

.step-index {
  margin-top: -11px;
  z-index: 1;
  margin-left: 12px;
  position: relative;
}

.step-check {
  margin-top: -10px;
  z-index: 1;
  margin-left: 6px;
  position: relative;
}

.bg-grey {
  background-color: #c4c4c4 !important;
}

.bg-pink {
  background-color: #CFB9FF !important;
}

.phoneInputField {
  height: 46px !important;
  border-radius: 30px 30px 30px 30px !important;
 
  width: 100% !important;
  bottom: 0.25rem !important;
  background-color: rgba(243, 244, 246, 1) !important;
  border-color: rgba(0, 0, 0, 1) !important;
  border-radius: 20px;
}

.country-dropdown {
  border-radius: 30px 0px 0px 30px !important;
  border-color: rgba(0, 0, 0, 1) !important;
  top: -4px !important;
  bottom: 4px !important;
}

.fc-orange {
  color: #FF815C;
}

.pricing-text {
  color: #FF815C;
  font-size: 32px;
}

.list-items {
  list-style: none;
}

.list-items li:before {
  content: '✓';
  padding-right: 12px;
}

.m-t-5 {
  margin-top: 5px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-12 {
  padding-left: 12px;
}

.card {
  display: flex;
  flex-direction: column;
}

.card .content {
  flex: 1 1 auto;
}

.btn-black-container {
  margin-top: 30px;
  margin-bottom: 52px;
}

.mtb-2 {
  margin-top: 0.625rem;
  margin-bottom: 0.4rem;
}

.btn-black-container-2 {
  margin-top: 30px;
  margin-bottom: 54px;
}

.plan-container {
  padding: 10px 0;
}

.text-indent {
  text-indent: 20px;
}

.image-box {
  width: 190px;
  height: 190px;
}

.bg-brown {
  background-color: #362921;
}

.button-box {
  max-width: 20rem;
  margin: 0 auto;
}

.m-b-25 {
  margin-bottom: 25%;
}

.border-violet {
  border-color: blueviolet;
}

.border-radius-20 {
  border-radius: 20px;
}
/* width */
html::-webkit-scrollbar {
  width: 15px;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
  box-shadow: inset 0 0 5px rgb(190, 190, 190);
  background: gray;
}

/* Track */
/* Handle */
html::-webkit-scrollbar-thumb {
  background: rgb(181, 181, 181);
  border-radius: 10px;
}
html::-webkit-scrollbar-thumb:hover {
  background: rgb(72, 72, 72);
  border-radius: 10px;
}

.rolling-banner {
  position: relative;
  width: 100%;
  height: 52px;
  background-color: #CFB9FF;
  overflow: hidden;
}
.rolling-banner .banner-link {
  display: block;
  height: 100%;
}
.rolling-banner .banner-contents {
  position: relative;
  animation: rolling-animation 20s infinite linear;
  @media screen and (max-width:768px) {
    animation: rolling-animation 50s infinite linear;
  }
}
.rolling-banner .banner-contents .banner-image {
  position: absolute;
  height: 41px;
}
.rolling-banner .banner-contents .banner-image.left {
  left: 0;
}
.rolling-banner .banner-contents .banner-image.right {
  right: 0;
}
@keyframes rolling-animation {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

@media screen and (max-width:768px) {
  .border-box {
    border: 2px solid #000;
    border-radius: 10px;
  }

  .m-t-6 {
    margin-top: 6rem !important;
  }

  .xs-m-t-9 {
    margin-top: 9rem !important;
  }

  .m-t-9 {
    margin-top: 9rem !important;
  }

  .mobile-scroll {
    height: calc(100vh - 111px);
    overflow-y: scroll;
  }

  @media screen and (min-width:767px) {
    .xs-m-t-9 {
      margin-top: 6rem !important;
    }
  }

  @media screen and (max-width:450px) {
    #responsive-text {
      font-size: 8vw;
    }
  }
}